import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [

  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import('../views/aboutMe/index.vue')
  },
  {
    path: '/concept',
    name: 'concept',
    component: () => import('../views/concept/index.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/portfolio/index.vue')
  },
  {
    path: '/bussinessCard',
    name: 'bussinessCard',
    component: () => import('../views/portfolio/bussinessCard.vue')
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () => import('../views/concept/articleDetail.vue')
  }
 
];
import { updateViewportSize } from '@/main.js'; 
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// // 导航守卫
router.beforeEach((to, from, next) => {
  // 获取视口宽高
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  console.log(viewportWidth,22);
  let isShow = true
  if (viewportWidth < 1080) {
    isShow = false
} else {
  isShow = true
}
  // 例如：
  Vue.prototype.$viewportWidth = viewportWidth;
  Vue.prototype.$viewportHeight = viewportHeight;
  Vue.prototype.$isShow = isShow;

  next()

});




export default router;
