import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import './style/font.css'

//引入ElementUI
// import '@/config/element-ui-config'
import { Carousel, CarouselItem } from 'element-ui'
import 'element-ui/lib/theme-chalk/base.css'
import 'element-ui/lib/theme-chalk/carousel.css'
import 'element-ui/lib/theme-chalk/carousel-item.css'


Vue.use(Carousel)
Vue.use(CarouselItem)



import VueRouter from 'vue-router'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
 return routerPush.call(this, location).catch(error=> error)}

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

