<template>
  <div id="app">
    <!-- <div style="background-color: red;">99999</div> -->
    <navBar />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <footerBar />
  </div>
</template>
<script>
import navBar from './layout/navBar.vue'
import footerBar from './layout/footerBar.vue';
export default {
  components: { navBar, footerBar },
  data() {
    return {

    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name !== 'portfolio' && to.name !== 'bussinessCard') {
        sessionStorage.removeItem('index')
      }
      next()
    })
  }

}

</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}


html {
  font-size: 10px;
  scroll-behavior: smooth;
  background: #000;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
