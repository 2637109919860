<template>
    <div class="fotter">
        <div class="container">
            <div class="contact">
                <div class="contact-item">
                    <div class="contact-type"> <img src="../assets/image/youxiang.png" alt="" class="icon">
                        <span>Email</span>
                    </div>
                    <div class="contact-text">iconchen@qq.com</div>
                </div>
                <div class="contact-item">
                    <div class="contact-type" @mouseover="showOverlay = true" @mouseleave="showOverlay = false"> <img
                            style="width: 15.75px;height: 12.71px;" src="../assets/image/weixin.png" alt=""
                            class="icon">
                        <span class="wechat">WeChat</span>
                    </div>
                    <div v-if="showOverlay" class="overlay">
                        <img src="../assets//image/weixin.jpg" alt="Overlay Image">
                    </div>
                    <div class="contact-text">Chen7239427</div>
                </div>
                <div class="beian" v-if="$isShow">
                    <span>Copyright © 2024 iconchen. Designed by Chen.</span>
                    <a class="record" href="https://beian.miit.gov.cn/">浙ICP备2021006913号-1</a>
                </div>
            </div>

            <div class="sitemap">
                <div class="sitemapBox">
                    <div class="sitemap_text">SITEMAP</div>
                    <div class="sitemap_list">
                        <div v-for="(item, index ) in headerList" :key="index" @click="onClick(item.path)">{{
                        item.title
                    }}</div>
                    </div>
                </div>
                <div class="back-to-top" v-if="$isShow">
                    <img v-if="showBackToTopButton" @click="scrollToTop" src="../assets/image/zhiding.png" />

                </div>



            </div>

            <div class="beian" v-if="!$isShow">
                <div>Copyright © 2024 iconchen. Designed by Chen.</div>
                <a class="record" href="https://beian.miit.gov.cn/">浙ICP备2021006913号-1</a>
            </div>

        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            showBackToTopButton: false,
            headerList: [
                { title: 'HOME', path: '/home' },
                { title: 'PORIFOLIO', path: '/portfolio' },
                { title: 'CONCEPT', path: '/concept' },
                { title: 'ABOUT ME', path: '/aboutMe' }
            ],
            showOverlay: false
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > window.innerHeight) {
                this.showBackToTopButton = true;
            } else {
                this.showBackToTopButton = false;
            }
        },

        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        onClick(path) {

            this.$router.push(path)

        }

    }
}


</script>

<style lang="scss" scoped>
.fotter {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100vw;
    margin-bottom: 10.4rem;
    overflow-x: hidden;
}

.container {
    width: 116rem;
    display: flex;
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-top: 13.6rem;

}

.contact-item .contact-text {
    margin: 2rem 0;
    color: #fff;
    font-size: 2rem;
}

.contact-item .icon {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.3rem;
}

.contact-type {
    color: rgba(225, 225, 225, 0.65);
    font-size: 1.6rem;
}

.beian {
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.45);
    margin-top: 8.6rem;
}

.wechat {
    text-decoration-line: underline;
    cursor: pointer;
}

.sitemap {
    color: #fff;
    margin-top: 10.5rem;

}

.sitemapBox {
    width: 28rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sitemap_text {
    width: 100%;
    height: 9rem;
    line-height: 9rem;
    font-size: 2rem;
    font-family: 'Alibaba-Medium';
    text-align: center;

}

.sitemap_list {
    width: 100%;
    border-right: 0.01rem solid #fff;

}

.sitemap_list div {

    cursor: pointer;
    font-family: 'Alibaba-Medium';
    font-size: 1.4rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;

}

.back-to-top {
    position: fixed;
    z-index: 3333;
    bottom: 10rem;
    right: 10rem;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    color: #fff;

}

.record {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.45);
    margin-left: 2.4rem;


}

.overlay {
    position: absolute;
    top: 0rem;

    img {
        width: 23rem;
        height: 23rem;
    }
}

/* 适配 */
@media only screen and (max-width:1080px) {

    .container {
        flex-direction: column;
        width: 100vw;
        padding: 0 5vw;
    }

    .sitemap {
        margin-top: 20px;
    }

    .sitemap_text {
        text-align: left;
    }

    .sitemap_list {
        border-right: none;

        div {
            text-align: left;
        }

    }

    .beian {
        margin-top: 5rem;


        div {
            margin-bottom: 12px;
        }

        a {
            margin-left: 0;
        }
    }

    .contact-item .contact-text {
        font-size: 2.4rem;
    }

    .contact-type {
        font-size: 1.8rem;
    }

    .sitemap_text {
        font-size: 2.4rem;
    }

    .overlay {
        top: 0rem;
    }


}
</style>