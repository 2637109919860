<template>
    <div class="nav">
        <div class="header-nav" id="header" v-if="isShow">
            <ul>
                <li v-for="(item, index) in headerList" :key="index">
                    <router-link :to="item.path">
                        <template v-if="index !== 2">
                            {{ item.title }}
                        </template>
                        <template v-else>
                            <img src="..//assets/image/logo_bai.png" alt="" class="img">
                        </template>
                    </router-link>



                </li>
            </ul>


        </div>
        <div v-else class="app-container">
            <div class="icon-nav">
                <div>
                    <img class="logo" src="../assets/appImage/logo.png" alt="" v-if="iconShow">
                </div>
                <div>
                    <img v-if="iconShow" class="logo" src="../assets/appImage/zhankai.png" alt="" @click="getIcon">
                    <img v-else class="logo" src="../assets/appImage/close.png" alt="" @click="getIcon">
                </div>



            </div>



        </div>
        <div class="app-nav" @click="iconShow = true" v-if="!iconShow">
            <div v-for="(item, index) in phoneList" :key="index">
                <router-link :to="item.path">
                    {{ item.title }}
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            headerList: [
                { title: 'HOME', path: '/home' },
                { title: 'PORIFOLIO', path: '/portfolio' },
                { title: 'HOME', path: '' },
                { title: 'CONCEPT', path: '/concept' },
                { title: 'ABOUT ME', path: '/aboutMe' }
            ],
            phoneList: [
                { title: '首页', path: '/home' },
                { title: '设计作品', path: '/portfolio' },
                { title: '设计理念', path: '/concept' },
                { title: '关于我', path: '/aboutMe' }
            ],
            isShow: this.$isShow == true ? true : false,
            iconShow: true,



        }
    },

    created() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },


    methods: {
        handleResize() {
            window.innerWidth
            if (window.innerWidth < 1080) {
                this.isShow = false
            } else {
                this.isShow = true
            }

        },
        getIcon() {
            this.iconShow = !this.iconShow

        },

    },

}




</script>
<style scoped lang="scss">
body {
    overflow-x: hidden;
}

.router-link-active {
    text-decoration: none;
    color: #fff;
}

a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.45);


}

.nav {
    width: 100vw;
    overflow-x: hidden !important;
}



.header-nav {
    background: #101010;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
    overflow-x: hidden;


    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        padding: 3rem 0;
        // margin-right: 6.5rem;


    }

    /* 定义动画 */
    @keyframes change-image {
        to {
            opacity: 1;

        }
    }

    li {
        width: 20rem;
        font-size: 1.6rem;
        color: rgba(225, 225, 225, 0.45);
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: 'Alibaba-Medium';

    }

    ul li {
        list-style: none;
    }



    li:hover {
        cursor: pointer;
    }


    .img {
        width: 5rem;
        height: 5rem;
    }




}


.app-container {
    width: 100vw;
    background: #000000;
    color: #fff;
    font-family: 'Alibaba-Medium';
    overflow-x: hidden !important;
    position: relative;

    .icon-nav {
        height: 5rem;
        padding: 0 5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 2px rgba(255, 255, 255, 0.15);

        .logo {
            width: 2.4rem;
            height: 2.4rem;
        }
    }





}

.app-nav {
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    z-index: 999;
    font-size: 48px;
    padding: 70px 0 70px 40px;
    border-bottom: solid 2px rgba(255, 255, 255, 0.15);
    background: #000000;
    overflow-x: hidden !important;
    flex-shrink: 0;
    font-size: 24px;

    div {
        margin-bottom: 20px;
    }

}
</style>